<template>
  <ct-centered-spinner v-if="isLoading" />
  <div v-else id="associated-pages-component">
    <h6 v-if="noPagesToShow" class="no-pages-text">
      No Pages Yet
    </h6>
    <a
      v-for="(page, index) in getPagesToShow"
      :key="page.id"
      class="associated-page-link"
      :aria-label="pageAriaLabels[index]"
      @click="navigateToAssociatedPage(page)"
    >
      <div class="associated-page-container">
        <h6 class="associated-page-text">
          {{ page.question }}
        </h6>
        <arrow-right class="arrow-icon" />
      </div>
      <hr
        v-if="index < getPagesToShow.length - 1"
        class="divider"
      >
    </a>
  </div>
</template>

<script>
import { faqMixin } from '@/mixins/faqMixin'

export default {
  name: 'AssociatedPages',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner.vue'),
    ArrowRight: () => import('@images/ui/arrow-right-231F20.svg'),
  },
  mixins: [faqMixin],
  props: {
    categoryId:{
      type: String,
      required: true,
    },
    maxPages: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    isLoading() {
      return !this.faqInformation || this.faqInformation.length === 0
    },
    getPagesToShow() {
      const category = this.faqInformation.find(cat => cat.id === this.categoryId)
      return category?.faq_pages.filter(page => page.is_visible).slice(0, this.maxPages) || []
    },
    noPagesToShow() {
      return this.getPagesToShow.length === 0
    },
    pageAriaLabels() {
      return this.getPagesToShow.map(page => `Navigate to ${page.question} link`)
    },
  },
  async mounted() {
    if (this.isLoading) await this.fetchFaqInformation()
  },
  methods: {
    navigateToAssociatedPage(page) {
      this.logFaqAssociatedPageNavigation(this.categoryId, page.id, {
        action: 'visit',
        type: 'link',
        categoryId: this.categoryId,
        pageQuestion: page.question,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
h6 {
  font-size: 1.0rem;
  font-weight: 400;
  color: #4E4E52;
}

#associated-pages-component {

  .no-pages-text {
    display: flex;
    justify-content: center;
    margin-top: 1.25rem;
  }

  .associated-page-link {

    &:hover {
      cursor: pointer;
    }

    .associated-page-container {
      padding: 1.0rem 2.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .associated-page-text {
        margin: 0;
      }

      ::v-deep .arrow-icon path {
        stroke: #4E4E52;
      }
    }

    .divider {
      width: 90%;
    }
  }
}

@media only screen and (max-width: 576px) {
  #associated-pages-component .associated-page-link {

    .associated-page-container {
      padding: 1.0rem;
      align-items: flex-start;

      .associated-page-text {
        max-width: 16.25rem;
        font-size: 0.875rem;
      }

      .arrow-icon {
        align-self: center;
      }
    }
  }
}
</style>
