var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("ct-centered-spinner")
    : _c(
        "div",
        { attrs: { id: "associated-pages-component" } },
        [
          _vm.noPagesToShow
            ? _c("h6", { staticClass: "no-pages-text" }, [
                _vm._v("\n    No Pages Yet\n  "),
              ])
            : _vm._e(),
          _vm._l(_vm.getPagesToShow, function (page, index) {
            return _c(
              "a",
              {
                key: page.id,
                staticClass: "associated-page-link",
                attrs: { "aria-label": _vm.pageAriaLabels[index] },
                on: {
                  click: function ($event) {
                    return _vm.navigateToAssociatedPage(page)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "associated-page-container" },
                  [
                    _c("h6", { staticClass: "associated-page-text" }, [
                      _vm._v("\n        " + _vm._s(page.question) + "\n      "),
                    ]),
                    _c("arrow-right", { staticClass: "arrow-icon" }),
                  ],
                  1
                ),
                index < _vm.getPagesToShow.length - 1
                  ? _c("hr", { staticClass: "divider" })
                  : _vm._e(),
              ]
            )
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }